'use strict';

const ONE_SECOND = 1000;

/**
 * Function to animate the scroll to the target section when a navigation link is clicked.
 *
 */
function smoothScrollToSection() {
    $(document).on('click', '.page-link-scroll', function (e) {
        e.preventDefault();
        var target = $(this).attr('href');
        if(target.length) {
            $('html, body').stop().animate({
                scrollTop: $(target).offset().top - $('header').outerHeight()
            }, ONE_SECOND);
        }
    });
}

module.exports = {
    smoothScrollToSection
};
